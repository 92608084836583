import CurrentName from './primitives/CurrentName';
import EditButton from './primitives/EditButton';
import EditIcon from './primitives/EditIcon';
import MFRButtonSpan from './primitives/MFRButtonSpan';
import MFRWrapper from './primitives/MFRWrapper';
import type { MouseEvent } from 'react';

const MFRSection = MFRWrapper.withComponent('section');

type Props = {
  className?: string;
  name: string;
  onEdit?: (e: MouseEvent<HTMLButtonElement>) => void;
};

export default function MFRName({ name, onEdit, className }: Props) {
  // ' Radio' is a string literal so that we can force a space between 'Favorites' and 'Radio'
  return (
    <MFRSection className={className}>
      <CurrentName>
        {name} Favorites
        <MFRButtonSpan>
          {' Radio'}
          {onEdit ? (
            <EditButton data-test="mfrname-editbtn" onClick={onEdit}>
              <EditIcon name="pencil" />
            </EditButton>
          ) : null}
        </MFRButtonSpan>
      </CurrentName>
    </MFRSection>
  );
}
