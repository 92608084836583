import BaseRenameMFR from './RenameMFR';
import FieldProvider from '../FieldProvider/FieldProvider';
import MFRName from './MFRName';
import { DEFAULT_MFR_NAME } from './constants';
import { getIsLoggedOut } from 'state/Session/selectors';
import { openSignupModal } from 'state/UI/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import type { FunctionComponent } from 'react';

const RenameMFR = FieldProvider()(BaseRenameMFR);

type Props = {
  canEdit?: boolean;
  className?: string;
  currentName?: string;
  onSave?: (value: string) => void;
};

const MFREditMode: FunctionComponent<Props> = ({
  canEdit = false,
  className,
  currentName = DEFAULT_MFR_NAME,
  onSave,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const isLoggedOut = useSelector(getIsLoggedOut);
  const dispatch = useDispatch();

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSave = (value: string) => {
    setIsEditing(false);

    if (onSave) onSave(value);
  };

  const handleEdit = () => {
    if (isLoggedOut) {
      dispatch(openSignupModal({ context: 'reg-gate' }));
    } else {
      setIsEditing(true);
    }
  };

  return isEditing ? (
    <RenameMFR
      className={className}
      getErrorMessage={(value: string) => {
        if (!value) {
          return 'Rename your Favorites Radio station now.';
        }
        if (value.length === 1) {
          return 'Name must be at least 2 characters.';
        }
        return '';
      }}
      initialValue={currentName}
      onCancel={handleCancel}
      onSave={handleSave}
    />
  ) : (
    <MFRName
      className={className}
      name={currentName}
      onEdit={canEdit ? handleEdit : undefined}
    />
  );
};

export default MFREditMode;
